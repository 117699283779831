import _ from "lodash";
import CRMService from "../service/CRMService";
import {
  FETCHING_CRM_CASE_LIST,
  FETCHING_CRM_CASE_DETAIL,
  FETCH_CRM_CASE_LIST,
  FETCH_CRM_CASE_DETAIL,
  FETCH_CRM_CASE_SECTIONS,
  FETCH_CRM_CASE_SECTION_ATTACHMENTS_LIST,
  FETCH_CRM_CASE_CATEGORIES,
  CLEAR_CRM_CASE_SECTION_ATTACHMENTS_LIST,
  ADD_CRM_CASE_MESSAGE,
  OPEN_CRM_CASE_MESSAGE,
  SEND_NEW_CRM_CASE,
  RESPONSE_NEW_CRM_CASE,
} from "./types";

import {
  FETCH_CRM_CASE_SUCCESSFUL_MSG,
  FETCH_CRM_CASE_FAILURE_MSG,
  CREATE_CRM_CASE_SUCCESSFUL_MSG,
  CREATE_CRM_CASE_FAILURE_MSG,
} from './messages';
import { parseResponse } from "../service";
import {openGlobalSnackbar} from "./globalActions";

export const actionSuccessfulCRMCasesFetch = (actionType, data, section) => {
  return {
    type: actionType,
    payload: {
      data: data,
      fetchedSectionCases: section,
      loadingCRMCases: false,
      message: FETCH_CRM_CASE_SUCCESSFUL_MSG
    }
  }
};

export const actionFailedCRMCasesFetch = actionType => {
  return {
    type: actionType,
    payload: {
      data: null,
      loadingCRMCases: false,
      message: FETCH_CRM_CASE_FAILURE_MSG
    }
  }
};

export const actionSuccessfulCRMCaseDetailFetch = (actionType, data) => {
  return {
    type: actionType,
    payload: {
      data: data,
      loadingCRMCase: false,
      message: FETCH_CRM_CASE_SUCCESSFUL_MSG
    }
  }
};

export const actionFailedCRMCaseDetailFetch = actionType => {
  return {
    type: actionType,
    payload: {
      data: null,
      loadingCRMCase: false,
      message: FETCH_CRM_CASE_FAILURE_MSG
    }
  }
};


export const fetchCRMCases = (section) => async (dispatch, getState) => {
  dispatch({
    type: FETCHING_CRM_CASE_LIST,
    payload: {
      loadingCRMCases: true,
    }
  });
  const _CRMService = new CRMService();
  return await _CRMService.getCRMCasesList(section)
  .then(
    response => {
      dispatch(
        actionSuccessfulCRMCasesFetch(FETCH_CRM_CASE_LIST, _.get(response, 'data.items', []), section)
      )
      return parseResponse(response);
    }
  )
  .catch(
    error => {
      dispatch(actionFailedCRMCasesFetch(FETCH_CRM_CASE_LIST));
      return parseResponse(error);
    }
  )
};

export const fetchCRMCaseDetail = (id) => async (dispatch, getState) => {
  dispatch({
    type: FETCHING_CRM_CASE_DETAIL,
    payload: {
      loadingCRMCase: true,
    }
  });
  const _CRMService = new CRMService();
  return await _CRMService.getCRMCaseDetail(id)
  .then(
    response => {
      // Order case mails by date
      let data = _.get(response, 'data', []);
      if( !_.isEmpty(data) && data.mails.length > 1 ){
        data.mails = data.mails.sort((a,b) => {
          return new Date(a.date) - new Date(b.date);
        })
      }
      dispatch(
        actionSuccessfulCRMCaseDetailFetch(FETCH_CRM_CASE_DETAIL, data)
      )
      return parseResponse(response);
    }
  )
  .catch(
    error => {
      dispatch(actionFailedCRMCaseDetailFetch(FETCH_CRM_CASE_DETAIL));
      return parseResponse(error);
    }
  )
};

export const fetchCRMCaseSections = (section) => async (dispatch, getState) => {
  const _CRMService = new CRMService();
  return await _CRMService.getCRMCaseSections(section)
  .then(
    response => {
      dispatch({
        type: FETCH_CRM_CASE_SECTIONS,
        payload: {
          data: response,
          fetchedSection: section
        }
      });
    }
  )
}

export const fetchCRMCaseCategories = (id) => async (dispatch, getState) => {
  const _CRMService = new CRMService();
  return await _CRMService.getCRMCaseCategories(id)
  .then(
    response => {
      dispatch({
        type: FETCH_CRM_CASE_CATEGORIES,
        payload: {
          data: response
        }
      });
    }
  )
}

export const fetchCRMCaseSectionsAttachmentsList = (id) => async (dispatch, getState) => {
  const _CRMService = new CRMService();
  return await _CRMService.getCRMCaseSectionAttachmentsList(id)
  .then(
    response => {
      dispatch({
        type: FETCH_CRM_CASE_SECTION_ATTACHMENTS_LIST,
        payload: {
          data: response
        }
      });
    }
  )
}

export const cleanSectionsAttachmentsList = () => async (dispatch, getState) => {
  dispatch({
    type: CLEAR_CRM_CASE_SECTION_ATTACHMENTS_LIST,
    payload: {}
  });
}

export const downloadCRMCaseSectionsAttachment = (sectionAttachmentId) => async () => {
  const _CRMService = new CRMService();
  return await _CRMService.getCRMCaseDownloadSectionAttachment(sectionAttachmentId)
  .then(
    response => {
      return parseResponse(response);
    }
  )
}

export const actionSuccessfulNewCRMCaseCreation = (actionType, data) => {
  return {
    type: actionType,
    payload: {
      data: data,
      message: CREATE_CRM_CASE_SUCCESSFUL_MSG,
      sendingCRMCase: false,
    }
  }
};

export const actionFailedNewCRMCaseCreation = actionType => {
  return {
    type: actionType,
    payload: {
      data: null,
      message: CREATE_CRM_CASE_FAILURE_MSG,
      sendingCRMCase: false,
    }
  }
};

export const sendNewCRMCase = data => async (dispatch) => {
  const _CRMService = new CRMService();
  dispatch({
    type: SEND_NEW_CRM_CASE,
    payload: {
      sendingCRMCase: true,
    }
  });
  return await _CRMService.postCRMCase(data)
  .then(
    response => {
      dispatch(
          openGlobalSnackbar(_.get(response, 'response.data.message', CREATE_CRM_CASE_SUCCESSFUL_MSG), false)
      );
      dispatch(
        actionSuccessfulNewCRMCaseCreation(RESPONSE_NEW_CRM_CASE, _.get(response, 'data', []))
      );
      return parseResponse(response);
    }
  )
  .catch(
    error => {
      dispatch(
        openGlobalSnackbar(_.get(error, 'response.data.message', CREATE_CRM_CASE_FAILURE_MSG), true)
      );
      dispatch(
        actionFailedNewCRMCaseCreation(RESPONSE_NEW_CRM_CASE)
      );
      return parseResponse(error);
    }
  );
}

export const sendNewCRMCaseMessage = (id, data) => async (dispatch, getState) => {
  const _CRMService = new CRMService();
  return await _CRMService.postCRMCaseMessage(id, data)
  .then(
    response => {
      const d = new Date();
      const date = d.getHours() + ":" + d.getMinutes() 
        + ":" + d.getSeconds() + " " + d.getDay()
        + "/" + d.getMonth() + "/" + d.getFullYear()
      dispatch({
        type: ADD_CRM_CASE_MESSAGE,
        payload: {
          data: {
            'message': data.message,
            'date': date,
            'from': 
              {
                'customer': true,
                'email': getState().user.email,
                'name': getState().user.name
              },
            'attachments': response.data
          }
        }
      });
      return parseResponse(response);
    }
  )
  .catch(
    error => {
      return parseResponse(error);
    }
  );
}

export const downloadAttachment = (caseId, attachmentId) => async () => {
  const _CRMService = new CRMService();
  return await _CRMService.getCRMCaseDownloadAttachment(caseId, attachmentId)
  .then(
    response => {
      return parseResponse(response);
    }
  )
}  

export const openCRMMessageDialog = () => (dispatch) => {
  dispatch({
    type: OPEN_CRM_CASE_MESSAGE,
    payload: {}
  })
}